
import { Component, Vue } from 'vue-property-decorator';
import Poller from '@/utils/poller';
import userProfileModule from '@/store/modules/userProfileModule';
import { sleep } from '@/utils/helpers';
import { isLoggedIn, refreshAccessToken } from '@/lib/auth';
import userPreference from '@/lib/userPreference';

let AlertsPoller: any;

@Component
export default class TasqMainBanner extends Vue {
  visible: boolean = false;
  message: string = '';
  type: string = 'error';
  closed: boolean = false;
  search: RegExp = /\*{2}(.*?)\*{2}/g;

  mounted() {
    this.startAlertsPolling();
  }

  get messageHTML() {
    return this.message.replace(this.search, '<strong>$1</strong>');
  }

  async close() {
    this.visible = false;
    await userPreference.setItem('alert-banner-details', {
      visible: this.visible,
      message: this.message,
    });
  }

  setContent({ AlertSettings }) {
    userPreference.getItem('alert-banner-details').then((data: any) => {
      if (data && data.message) {
        if (data.message === AlertSettings.msg) {
          return;
        }
      }
      this.message = AlertSettings.msg;
      this.visible = AlertSettings.visibility;
    });
  }

  getOperatorDetails() {
    if (!isLoggedIn()) {
      refreshAccessToken();
      return;
    }

    return new Promise((resolve) => {
      userProfileModule.getOperatorDetails().then((operator_details) => {
        resolve(operator_details);
      });
    });
  }

  startAlertsPolling() {
    if (AlertsPoller) {
      return;
    }
    AlertsPoller = new Poller({ interval: 75000 })
      .onCheck(() => this.getOperatorDetails())
      .onEachUpdate(this.setContent)
      .onTimeout(() => AlertsPoller.start())
      .start();
  }
}
